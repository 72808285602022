import { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import tefalLogo from "../../assets/images/logo.png";
import dottedLine from "../../assets/images/lineunderlogo.png";
import powerOff from "../../assets/images/poweroff.png";
import powerOn from "../../assets/images/poweron.png";
import fromIcon from "../../assets/images/fromicon.png";
import toIcon from "../../assets/images/toicon.png";
import arrow from "../../assets/images/startarrow.png";
import CircularSpinner from "../CircularSpinner/CircularSpinner";
import ScheduleList from "../ScheduleList/ScheduleList";

function generateRandomString() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const HomePage = (props) => {
  // Device button/switching state
  const [isOn, setIsOn] = useState(null);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isOnline, setIsOnline] = useState(null);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [statusError, setStatusError] = useState(null);
  const [switchingError, setSwitchingError] = useState(null);

  // Scheduling state
  const [selectedDays, setSelectedDays] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [fromTime, setFromTime] = useState("10:00");
  const [toTime, setToTime] = useState("12:00");
  const [fetchingSchedules, setFetchingSchedules] = useState(false);
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [scheduledTasksError, setScheduledTasksError] = useState(null);

  // Adding schedule state
  const [addingSchedule, setAddingSchedule] = useState(null);
  const [addingScheduleError, setAddingScheduleError] = useState(null);

  // List of schedules stat
  const [viewScheduledTasks, setViewScheduledTasks] = useState(false);

  // Refreshing data state
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchDeviceStatus = async () => {
      setFetchingStatus(true);
      const response = await fetch(
        "https://tefal-backend.craftparadox.net/api/v1.0/device-info/"
      );
      if (response.status !== 200) {
        setStatusError("Unable to detect device status.");
        return;
      }
      const resData = await response.json();
      setIsOn(resData.status[0].value);
      setIsOnline(resData.online);
      setFetchingStatus(false);
    };

    fetchDeviceStatus();
  }, []);

  useEffect(() => {
    const fetchSchedules = async () => {
      setFetchingSchedules(true);
      const response = await fetch("https://tefal-backend.craftparadox.net/api/v1.0/schedules/");
      const resData = await response.json();
      if (response.status !== 200) {
        setScheduledTasksError("Unable to fetch schedules.");
        setFetchingSchedules(false);
        return;
      }
      setScheduledTasks(resData);
      setFetchingSchedules(false);
    };

    fetchSchedules();
  }, [refresh]);

  const toggleHandler = async () => {
    if (isSwitching) return;
    setSwitchingError(null);
    setIsSwitching(true);
    if (isOn === null) return;
    const response = await fetch("https://tefal-backend.craftparadox.net/api/v1.0/switch/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        value: !isOn,
      }),
    });
    const resData = await response.json();
    if (response.status !== 200) {
      setSwitchingError(resData.error);
      setIsSwitching(false);
      return;
    }
    setIsOn((prevVal) => !prevVal);
    setIsSwitching(false);
  };

  const selectDayHandler = (index) => {
    let newSelectedDays = [...selectedDays];
    newSelectedDays[index] = newSelectedDays[index] === 1 ? 0 : 1;
    setSelectedDays(newSelectedDays);
  };

  const addScheduleHandler = async () => {
    setAddingScheduleError(null);
    setAddingSchedule(true);
    const fromData = {
      value: true,
      alias_name: generateRandomString(),
      time: fromTime,
    };
    const toData = {
      value: false,
      alias_name: generateRandomString(),
      time: toTime,
    };
    const atLeastOneDaySelected = selectedDays.includes(1);
    const selectedDaysAsString = selectedDays.join("");
    if (atLeastOneDaySelected) {
      fromData["loops"] = selectedDaysAsString;
      toData["loops"] = selectedDaysAsString;
    }

    // Send POST request for the FROM TIME
    const fromResponse = await fetch(
      "https://tefal-backend.craftparadox.net/api/v1.0/schedule-task/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fromData),
      }
    );
    if (fromResponse.status !== 200) {
      setAddingScheduleError(
        "Something went wrong while saving the FROM time."
      );
      setAddingSchedule(false);
      return;
    }

    // Send POST request for the TO TIME
    const toResponse = await fetch(
      "https://tefal-backend.craftparadox.net/api/v1.0/schedule-task/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toData),
      }
    );
    if (toResponse.status !== 200) {
      setAddingScheduleError("Something went wrong while saving the TO time.");
      setAddingSchedule(false);
      return;
    }

    // Response data from 1st request
    const fromResData = await fromResponse.json();

    // Response data from 2nd request
    const toResData = await toResponse.json();

    // Send another POST request after we recieve the timerID in the repsone
    const postSchedulingResponse = await fetch(
      "https://tefal-backend.craftparadox.net/api/v1.0/add-schedule/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f_timer_id: fromResData.timer_id,
          t_timer_id: toResData.timer_id,
          from_time: fromTime,
          to_time: toTime,
          days: selectedDaysAsString,
        }),
      }
    );
    if (postSchedulingResponse.status !== 201) {
      setAddingScheduleError("Something went wrong while saving the schedule.");
      setAddingSchedule(false);
      return;
    }
    setSelectedDays([0, 0, 0, 0, 0, 0, 0]);
    setFromTime("10:00");
    setToTime("12:00");
    setRefresh((prevVal) => !prevVal);
    setAddingSchedule(false);
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.bar}></div>
        <div className={classes.content}>
          <div>
            <div>
              <img src={tefalLogo} alt="Tefal" className={classes.logo} />
            </div>
            <div>
              <img
                src={dottedLine}
                alt="Line"
                className={classes["dotted-line"]}
              />
            </div>
            {!viewScheduledTasks && (
              <>
                {isOn === null && !statusError && (
                  <div className={classes["loading-status"]}>
                    <CircularSpinner />
                  </div>
                )}
                {statusError && <p className={classes.error}>{statusError}</p>}
                {switchingError && (
                  <p className={classes.error}>{switchingError}</p>
                )}
                {!fetchingStatus && !isOnline && <p className={classes.error}>The device is offline. You can't switch it on or off.</p>}
                {!fetchingStatus && isOn !== null && (
                  <div className={classes.power} onClick={isOnline ? toggleHandler : null}>
                    {isSwitching && <div className={classes.disable}></div>}
                    <img src={isOn ? powerOn : powerOff} alt="Power button" />
                  </div>
                )}
                <div className={classes.line}></div>
                {addingScheduleError && (
                  <p className={classes.error}>{addingScheduleError}</p>
                )}
                <div className={classes["day-selector"]}>
                  <p>Select days to schedule timer</p>
                  <div className={classes.days}>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(0)
                      }
                      className={selectedDays[0] === 1 ? classes.selected : ""}
                    >
                      S
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(1)
                      }
                      className={selectedDays[1] === 1 ? classes.selected : ""}
                    >
                      M
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(2)
                      }
                      className={selectedDays[2] === 1 ? classes.selected : ""}
                    >
                      T
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(3)
                      }
                      className={selectedDays[3] === 1 ? classes.selected : ""}
                    >
                      W
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(4)
                      }
                      className={selectedDays[4] === 1 ? classes.selected : ""}
                    >
                      T
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(5)
                      }
                      className={selectedDays[5] === 1 ? classes.selected : ""}
                    >
                      F
                    </div>
                    <div
                      onClick={
                        addingSchedule ? null : () => selectDayHandler(6)
                      }
                      className={selectedDays[6] === 1 ? classes.selected : ""}
                    >
                      S
                    </div>
                  </div>
                </div>
                <div className={classes.time}>
                  <div className={classes["from-time"]}>
                    <div>
                      <img src={fromIcon} alt="Time from" width="50px" />
                      <p>From</p>
                    </div>
                    <input
                      type="time"
                      id="fromtime"
                      name="fromtime"
                      required
                      disabled={addingSchedule}
                      onChange={(e) => setFromTime(e.target.value)}
                      value={fromTime}
                    />
                  </div>
                  <div className={classes["to-time"]}>
                    <div>
                      <img src={toIcon} alt="Time to" width="50px" />
                      <p>To</p>
                    </div>
                    <input
                      type="time"
                      id="totime"
                      name="totime"
                      required
                      onChange={(e) => setToTime(e.target.value)}
                      value={toTime}
                    />
                  </div>
                </div>
                {addingSchedule && <CircularSpinner />}
                {!addingSchedule && (
                  <div
                    onClick={addScheduleHandler}
                    className={`${classes.btn}`}
                  >
                    <img src={arrow} alt="Arrow" width="15px" />
                    <p>Start</p>
                  </div>
                )}
              </>
            )}
            {viewScheduledTasks && (
              <>
                {fetchingSchedules && <CircularSpinner />}
                {!fetchingSchedules && (
                  <ScheduleList
                    schedules={scheduledTasks}
                    scheduleError={scheduledTasksError}
                    refreshDataHandler={() => {
                      setRefresh((prevVal) => !prevVal);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {(viewScheduledTasks || scheduledTasks.length > 0) && (
        <button
          className={classes["view-hide-tasks"]}
          onClick={() => {
            setViewScheduledTasks((prevVal) => !prevVal);
          }}
        >
          {viewScheduledTasks
            ? scheduledTasks.length > 0
              ? "Hide Schedules"
              : "Back to home"
            : "View Schedules"}
        </button>
      )}
    </div>
  );
};

export default HomePage;
