import { useState } from "react";
import classes from "./ScheduleList.module.css";

const DAY_LABELS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const convertTo12HourFormat = (timeString) => {
  // Split the time string into hours and minutes
  var timeComponents = timeString.split(":");
  var hours = parseInt(timeComponents[0], 10);
  var minutes = timeComponents[1];

  // Determine AM or PM
  var meridiem = hours < 12 ? "AM" : "PM";

  // Convert hours to 12-hour format
  if (hours === 0) {
    hours = 12; // 0 hour in 12-hour format is 12 AM
  } else if (hours > 12) {
    hours = hours - 12;
  }

  // Return the formatted time string
  return hours + ":" + minutes + " " + meridiem;
};

const ScheduleList = ({ schedules, scheduleError, refreshDataHandler }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deletingError, setDeletingError] = useState(null);
  const [switchingError, setSwitchingError] = useState(null);
  const [itemsBeingDeleted, setItemsBeingDeleted] = useState([]);
  const [itemsBeingEdited, setItemsBeingEdited] = useState([]);

  const toggleScheduleHandler = async (ftimerid, ttimerid, enable, id) => {
    setItemsBeingEdited([...itemsBeingEdited, id]);
    const response = await fetch("https://tefal-backend.craftparadox.net/api/v1.0/task-state/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        f_timer_id: ttimerid,
        t_timer_id: ftimerid,
        enable: enable,
      }),
    });
    if (response.status !== 200) {
      setSwitchingError("Something went wrong while chaning a schedule.");
      setItemsBeingEdited(itemsBeingEdited.filter((item) => item !== id));
      return;
    }
    setItemsBeingEdited(itemsBeingEdited.filter((item) => item !== id));
    refreshDataHandler();
  };

  const deleteScheduleHandler = async (id) => {
    setItemsBeingDeleted([...itemsBeingDeleted, id]);
    const response = await fetch(
      `https://tefal-backend.craftparadox.net/api/v1.0/schedule/${id}/`,
      {
        method: "DELETE",
      }
    );
    if (response.status !== 204) {
      setDeletingError("Something went wrong while deleting a shecdule");
      setIsEditing(false);
      setItemsBeingDeleted(itemsBeingDeleted.filter((item) => item !== id));
      return;
    }
    setItemsBeingDeleted(itemsBeingDeleted.filter((item) => item !== id));
    refreshDataHandler();
  };

  return (
    <div>
      {scheduleError && <p className={classes.error}>{scheduleError}</p>}
      {deletingError && <p className={classes.error}>{deletingError}</p>}
      {switchingError && <p className={classes.error}>{switchingError}</p>}
      {!scheduleError &&
        schedules.map((schedule, i) => (
          <div key={schedule.id} className={classes.card}>
            <div className={classes.info}>
              <h4>
                {convertTo12HourFormat(schedule.from_time)} -{" "}
                {convertTo12HourFormat(schedule.to_time)}
              </h4>
              <div className={classes.days}>
                {!schedule.days.includes("1") && <span>Once</span>}
                {schedule.days.includes("1") &&
                  schedule.days
                    .split("")
                    .map((character, idx) => (
                      <span key={idx}>
                        {character === "1" ? DAY_LABELS[idx] : ""}{" "}
                      </span>
                    ))}
              </div>
            </div>
            <div>
              {itemsBeingEdited.includes(schedule.id) && (
                <small> Switching...</small>
              )}
              {!itemsBeingEdited.includes(schedule.id) && (
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    disabled={isEditing}
                    checked={schedule.toggle}
                    onChange={() =>
                      toggleScheduleHandler(
                        schedule.f_timer_id,
                        schedule.t_timer_id,
                        !schedule.toggle,
                        schedule.id
                      )
                    }
                  />
                  <span className={`${classes.slider} ${classes.round}`}></span>
                </label>
              )}
              {itemsBeingDeleted.includes(schedule.id) && (
                <small> Deleting...</small>
              )}
              {!itemsBeingDeleted.includes(schedule.id) && (
                <button
                  className={classes["delete-btn"]}
                  onClick={() => {
                    deleteScheduleHandler(schedule.id);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ScheduleList;
